import React from "react"

import { Button, Container, Typography } from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { Link as button, useMatch } from "react-router-dom"

import { SCHOOL_PLATFORM_URL } from "utils/constants"
import { routes } from "utils/routes"

import NavLogo from "./NavLogo"
import { NavbarProps } from "./types"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.common.white[500],
    maxHeight: "62px",
    height: "62px",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  containerRoot: {
    width: "100%",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },

  mobileNavLink: {
    color: theme.colors.common.black[600],
    textDecoration: "none",
  },

  link: {
    color: "inherit",
    textDecoration: "none",
  },

  menu: {
    width: "100vw",
    height: "100vh",
    background: "white",
  },
}))

const SchoolNavbar = ({
  className,
  gutterBottom = true,
  slotEnd,
  slotStart,
}: NavbarProps) => {
  const classes = useStyles()

  const matchLoginRoute = useMatch({
    path: routes.school.login,
  })
  const matchSignUpRoute = useMatch({
    path: routes.school.signUp,
  })

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(classes.root, "py-1 flex items-center", className)}
        >
          <Container className={classes.containerRoot}>
            <div className={clsx(classes.container)}>
              <NavLogo href={SCHOOL_PLATFORM_URL} slotStart={slotStart} />

              {/* End */}
              <div className="flex gap-1 items-center">
                <>
                  {matchLoginRoute && (
                    <div className="flex">
                      <Typography>
                        <span className="hidden sm:inline-block">
                          Don’t have a Suraasa account?&nbsp;
                        </span>
                        <Button
                          component={button}
                          to={routes.school.signUp}
                          variant="link"
                        >
                          Sign up
                        </Button>
                      </Typography>
                    </div>
                  )}
                  {slotEnd && slotEnd}
                  {slotEnd
                    ? null
                    : matchSignUpRoute && (
                        <div className="flex">
                          <Typography>
                            <span className="hidden sm:inline-block">
                              Already have an account?&nbsp;
                            </span>
                            <Button
                              component={button}
                              to={routes.school.login}
                              variant="link"
                            >
                              Sign In
                            </Button>
                          </Typography>
                        </div>
                      )}
                </>
              </div>
            </div>
          </Container>
        </nav>
      </div>
    </>
  )
}

export default SchoolNavbar
