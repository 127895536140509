import React from "react"

import { ReactComponent as MiniLogo } from "assets/logos/suraasa-colored-small.svg"
import { ReactComponent as FullLogo } from "assets/logos/suraasa-logo-new.svg"
import clsx from "clsx"

type Props = {
  className?: string
  href?: string
  /**
   * Replaces Suraasa full logo with Suraasa mini logo
   * and appends slotStart children to the right of the updated logo
   */
  slotStart?: React.ReactElement | null
}

const NavLogo = ({ className, href = "#", slotStart }: Props) => (
  <div className={clsx("flex gap-1 items-center", className)}>
    <a className="md:hidden" href={href}>
      <MiniLogo height="40" width="50" />
    </a>

    <a className="hidden md:block" href={href}>
      {slotStart ? (
        <MiniLogo height="40" width="50" />
      ) : (
        <FullLogo width="150px" />
      )}
    </a>
    {slotStart && slotStart}
  </div>
)

export default NavLogo
