import { AxiosRequestConfig, AxiosResponse } from "axios"

/**
 * Data type for `204 No Content` response
 */
export type NoContentType = null

/**
 * Success response format
 * for codes in the 2xx range
 */
export type SuccessResponse<T> = {
  /**
   * API Request was successful
   * Always true for 2xx codes
   */
  isSuccessful: true
  /**
   * Axios response transformed into a usable format
   * Allowing to avoid writing `res.data.data.data` everywhere
   */
  data: T
  /**
   * Contains the original response returned by axios.
   * Is useful when the consumer would like to access
   * other properties of the request/response which is very rare
   */
  __data?: AxiosResponse
}

/**
 * Success response format with pagination
 * for codes in the 2xx range
 */
export type PaginatedResponse<T> = {
  /**
   * API Request was successful
   * Always true for 2xx codes
   */
  isSuccessful: true
  /**
   * Axios response transformed into a usable format
   * Allowing to avoid writing `res.data.data.data` everywhere
   */
  data: {
    data: T
    total: number
    nextPage: null | number
    previousPage: null | number
  }
  /**
   * Contains the original response returned by axios.
   * Is useful when the consumer would like to access
   * other properties of the request/response which is very rare
   */
  __data?: AxiosResponse
}

/**
 * Error response format
 * for codes in the 4xx range
 */
export type ErrorResponse = {
  /**
   * API Request was successful
   * Always false for errors
   */
  isSuccessful: false
  /**
   * True if the server responds with status code 500
   */
  is500: boolean
  /**
   * True if either of the following occurs:
   * - There is a network failure on the client
   * - The server is unresponsive
   * - The request timed out
   */
  isNetworkFailure: boolean
  /**
   * Response status code
   */
  statusCode?: number
  /**
   * Object containing a generic error message and 'key specific' error
   * There will always be one or the other. Not both together
   */
  errors: {
    /**
     * A generic error message describing the error (new structure)
     */
    message?: string
    /**
     * A generic error message describing the error (old structure)
     */
    detail?: string
    /**
     * These string keys contain the name of the key where the error has occurred
     */
    fieldErrors?: {
      [key: string]: string
    }
  }
}

/**
 * Success and failure response of the API
 */
export type APIResponse<T> = SuccessResponse<T> | ErrorResponse

/**
 * Success and failure response of the API
 */
export type PaginatedAPIResponse<T> = PaginatedResponse<T> | ErrorResponse

/**
 * `POST` API Arguments
 */
export type Config = Pick<AxiosRequestConfig, "data" | "headers" | "params">

type ParamType = string | number | null

export type URLParams<T extends string = ""> = {
  urlParams: {
    [key in T extends "" ? string : T]: ParamType
  }
}
/**
 * `PUT/PATCH/DELETE` API arguments with any custom URL params
 * Example:
 * `/profile/${id}/`
 *             ^^ This `id` is extracted from `urlParams`
 */
export type ConfigWithURLParams<T extends string = ""> = Config & URLParams<T>

type UrlFunction = (...args: ParamType[]) => string
type UrlKey = Record<string, UrlFunction>

export function validateUrls<T extends UrlKey | Record<string, UrlKey>>(
  urls: T
): T {
  return urls
}
