import { validateUrls } from "api/types"
import { getServiceURL } from "api/utils"
import { ServicePrefix } from "utils/constants"

const getSolisURL = getServiceURL(ServicePrefix.solis)
const getPolarisURL = getServiceURL(ServicePrefix.polaris)

export const urls = validateUrls({
  auth: {
    getUserDetails: () => getSolisURL("/v1/users/fetch/"),
    login: () => getSolisURL("/v1/auth/sso/login/"),
    logout: () => getSolisURL("/v1/auth/logout/"),
    refreshToken: () => getSolisURL("/v1/auth/refresh-token/"),
    generateAuthCode: () => getSolisURL("/v1/auth/codes/"),
    signUp: () => getSolisURL("/v1/auth/signup/"),
    forgotPassword: () => getSolisURL("/v1/auth/forgot-password/"),
    changePassword: () => getSolisURL("/v1/auth/change-password/"),
    resetPassword: (uuid, token, timestamp) =>
      getSolisURL(`/v1/auth/reset-password/${uuid}/${token}/${timestamp}/`),
    sendUserOTP: () => getSolisURL("/v1/users/otp/"),
    verifyUserOTP: () => getSolisURL("/v1/users/otp/verify/"),
  },

  closedBeta: {
    verifySignature: (id, token) =>
      getSolisURL(`/v1/closed-beta/users/${id}/verify/${token}/`),
  },

  skills: {
    getPublicSubmission: submissionId =>
      `/assignments/submissions/${submissionId}/`,
    downloadAttachment: attachmentId =>
      `/assignments/submissions/attachments/${attachmentId}/`,
    publicSkillProfile: username =>
      `/analytics/public/user-skill-portfolio/${username}/`,
    privateSkillProfile: () => `/analytics/public/user-skill-portfolio/`,

    toggleVisibility: () => `/assignments/toggle-visibility/`,
  },

  config: {
    // base: () => `/settings/user-configuration/`,
    preferences: () => getPolarisURL(`/v1/users/settings/`),
  },
})
