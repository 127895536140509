import React, { useContext } from "react"

import {
  Avatar,
  Container,
  IconButton,
  Menu,
  MenuItem,
} from "@suraasa/placebo-ui"
import clsx from "clsx"
import { createUseStyles } from "react-jss"
import { useNavigate } from "react-router"

import { LogOut, Settings } from "iconoir-react"

import { GlobalContext } from "components/GlobalState"
import { getAuthInfo } from "utils/auth"
import { routes } from "utils/routes"

import PartnerNavLogo from "./PartnerNavLogo"
import { NavbarProps } from "./types"

const useStyles = createUseStyles(theme => ({
  root: {
    backgroundColor: theme.colors.common.white[500],
    maxHeight: "62px",
    height: "62px",
    borderBottom: `1px solid ${theme.colors.onSurface[200]}`,
  },

  containerRoot: {
    width: "100%",
  },

  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
  },

  mobileNavLink: {
    color: theme.colors.common.black[600],
    textDecoration: "none",
  },

  link: {
    color: "inherit",
    textDecoration: "none",
  },

  menu: {
    width: "100vw",
    height: "100vh",
    background: "white",
  },
}))

const PartnerNavbar = ({
  className,
  gutterBottom = true,
  slotEnd,
}: NavbarProps) => {
  const classes = useStyles()

  const authInfo = getAuthInfo()
  const isLoggedIn = Boolean(authInfo)
  const { user } = useContext(GlobalContext)

  const navigate = useNavigate()

  return (
    <>
      <div
        className={clsx({
          "mb-5": gutterBottom,
        })}
      >
        <nav
          className={clsx(classes.root, "py-1 flex items-center", className)}
        >
          <Container className={classes.containerRoot}>
            <div className={clsx(classes.container)}>
              <PartnerNavLogo />

              {/* End */}
              <div className="flex gap-1 items-center">{slotEnd}</div>
              {isLoggedIn && user && (
                <div>
                  <Menu
                    align="end"
                    menuButton={
                      <IconButton size="md">
                        <Avatar
                          color="two"
                          name={`${user.firstName} ${user.lastName}`}
                          src={user.photo ?? undefined}
                        />
                      </IconButton>
                    }
                  >
                    <MenuItem
                      startAdornment={<Settings />}
                      onClick={() => {
                        navigate(`${routes.editProfile}?tab=accountDetails`)
                      }}
                    >
                      Settings
                    </MenuItem>
                    <MenuItem
                      color="critical"
                      startAdornment={<LogOut />}
                      onClick={() => {
                        navigate(routes.logout)
                      }}
                    >
                      Sign out
                    </MenuItem>
                  </Menu>
                </div>
              )}
            </div>
          </Container>
        </nav>
      </div>
    </>
  )
}

export default PartnerNavbar
