export const RTL_LANGUAGE_CODES = ["ar"]
export const DEFAULT_LANGUAGE_CODE = "en"

export const PAGE_SIZE = 10

export const AUTHORIZATION_HEADER_PREFIX = "Bearer"
export const SCHOOL_PLATFORM_URL = process.env
  .REACT_APP_SCHOOL_PLATFORM_URL as string
export const SVP_PLATFORM_URL = process.env.REACT_APP_SVP_PLATFORM_URL as string
export const LEARN_PLATFORM_URL = process.env
  .REACT_APP_LEARN_PLATFORM_URL as string
export const PARTNER_PLATFORM_URL = process.env
  .REACT_APP_PARTNER_PLATFORM_URL as string
export const CENTRE_PLATFORM_URL = process.env
  .REACT_APP_CENTRE_PLATFORM_URL as string
export const PROF_PLATFORM_URL = process.env
  .REACT_APP_PROF_PLATFORM_URL as string
export const IQA_PLATFORM_URL = process.env.REACT_APP_IQA_PLATFORM_URL as string
export const ADMISSIONS_PLATFORM_URL = process.env
  .REACT_APP_ADMISSIONS_ADMIN_PLATFORM_URL as string

export const BROWSER_STORAGE_KEYS = {
  auth: "auth",
  tempDeviceId: "tempDeviceId",
}

export enum Product {
  learning = 1,
  school = 2,
  partner = 3,
  internal = 4,
  center = 5,
}

export enum ServicePrefix {
  solis = "solis",
  gravity = "gravity",
  nebula = "nebula",
  kepler = "kepler",
  doppler = "doppler",
  analytics = "analytics",
  polaris = "polaris",
}

export enum Platforms {
  student = "Student",
  studentJobs = "Student Jobs",
  centerHead = "Centre Head",
  centreAdmin = "Centre Admin",
  professor = "Professor",
  serviceAdmin = "Service Admin",
  iqa = "Internal Quality Assessor",
  svp = "School Verification Portal",
  school = "School",
  apiDocs = "API Docs",
  olympiadPartner = "Olympiad Partner",
  applicationFormAdmin = "Application Form Admin",
}

export enum ProfileNavTabs {
  personalDetails = "Personal Details",
  contactDetails = "Contact Details",
  accountDetails = "Account Details",
  notificationPreferences = "Notification Preferences",
  emailPreferences = "Email Preferences",
  privacy = "Privacy",
}

export enum LanguageProficiency {
  ELEMENTARY = 1,
  LIMITED_WORKING,
  PROFESSIONAL_WORKING,
  FULL_PROFESSIONAL,
  NATIVE,
}

export const DEFAULT_PROFILE_COVER = "/assets/default-cover.png"

export const MAX_VIDEO_PORTFOLIO_DURATION = 3 // in minutes
export const MAX_VIDEO_PORTFOLIO_SIZE = 200 // in MB
export const VIDEO_PORTFOLIO_FORMATS = [
  ".mp4",
  ".mov",
  ".avi",
  ".heif",
  ".hevc",
]

export enum ModeOfLearning {
  ONLINE = 1,
  CLASSROOM,
  BLENDED,
}

export const modeOfLearningChoices = [
  { label: "Online", value: ModeOfLearning.ONLINE },
  {
    label: "Classroom Programme (offline)",
    value: ModeOfLearning.CLASSROOM,
  },
  {
    label: "Blended (Online & Classroom)",
    value: ModeOfLearning.BLENDED,
  },
]
export const acceptedImageTypes = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "image/webp",
]

export const Settings = {
  preferredLanguage: "preferred-language",
  pushNotifications: "push-notifications",
  emailNotifications: "email-notifications",
  eventBasedEmails: "event-based-emails",
  promotionalEmails: "promotional-emails",
} as const
