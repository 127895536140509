import React, { createContext, useEffect, useState } from "react"

import { NotificationList, useNotifications } from "@suraasa/notifications"
import { Outlet } from "react-router-dom"

import api from "api"
import axios from "api/axios"
import type { Country, Language, Subject } from "api/resources/global/types"
import { User } from "api/resources/users/types"
import { getAuthInfo } from "utils/auth"
import { formatNotifications, NotificationAction } from "utils/notifications"

type GlobalUserState = Pick<User, "photo" | "firstName" | "lastName">

type Context = {
  countries: Country[]
  languages: Language[]
  subjects: Subject[]

  user: GlobalUserState | null
  setUser: (user: Partial<GlobalUserState>) => void
  notifications: NotificationList<NotificationAction>
}

export const GlobalContext = createContext<Context>({
  countries: [],
  languages: [],
  subjects: [],

  user: null,
  setUser: () => {},
  notifications: { data: null, unreadCount: 0, totalCount: 0 },
})

const GlobalState = () => {
  const [countries, setCountries] = useState<Country[]>([])
  const [languages, setLanguages] = useState<Language[]>([])
  const [subjects, setSubjects] = useState<Subject[]>([])
  const [user, setUser] = useState<Context["user"]>(null)
  const [notifications] = useNotifications({
    axiosInstance: axios,
  })

  if (notifications.data) {
    notifications.data = formatNotifications(notifications.data)
  }

  useEffect(() => {
    const getData = async () => {
      const listCountries = api.global.listCountries({ params: { page: "-1" } })
      const listLanguages = api.global.listLanguages({ params: { page: "-1" } })
      const listSubjects = api.global.listSubjects({ params: { page: "-1" } })

      const [countriesRes, languagesRes, subjectsRes] = await Promise.all([
        listCountries,
        listLanguages,
        listSubjects,
      ])

      if (countriesRes.isSuccessful) setCountries(countriesRes.data)

      if (languagesRes.isSuccessful) setLanguages(languagesRes.data)

      if (subjectsRes.isSuccessful) setSubjects(subjectsRes.data)
    }
    getData()
  }, [])

  useEffect(() => {
    const getProfile = async () => {
      const res = await api.profile.retrieve({
        urlParams: { username: null },
      })

      if (res.isSuccessful) {
        setUser({
          firstName: res.data.user.firstName ?? "",
          lastName: res.data.user.lastName ?? "",
          photo: res.data.picture,
        })
      }
    }

    if (getAuthInfo()) getProfile()
  }, [])

  return (
    <GlobalContext.Provider
      value={{
        countries,
        languages,
        subjects,
        notifications,
        user,
        setUser: newState => {
          setUser(prevState => {
            if (!prevState) return newState as GlobalUserState
            return { ...prevState, ...newState }
          })
        },
      }}
    >
      <Outlet />
    </GlobalContext.Provider>
  )
}

export default GlobalState
