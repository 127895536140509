import { useEffect, useState } from "react"

import { Typography } from "@suraasa/placebo-ui"
import { Outlet } from "react-router-dom"

import api from "api"
import LoadingOverlay from "components/shared/LoadingOverlay"
import { getAuthInfo, saveAuthInfo } from "utils/auth"
import { Platforms } from "utils/constants"

function Dashboard() {
  const [authVerified, setAuthVerified] = useState(false)

  useEffect(() => {
    const verifyAuth = async () => {
      const res = await api.profile.emails.list()
      if (res.isSuccessful) {
        setAuthVerified(true)
      } else {
        console.error("TOKEN EXPIRED")
      }
    }

    verifyAuth()
  })

  useEffect(() => {
    /**
     * This is temporary. For use cases where user was logged in already
     * without having `platform` in their auth.
     * We can remove this 2-3 weeks after deployment.
     */
    const auth = getAuthInfo()
    if (auth && !auth.platform) {
      saveAuthInfo({ ...auth, platform: Platforms.student })
    }
  }, [])

  if (!authVerified) {
    return (
      <LoadingOverlay>
        <Typography className="mt-2" variant="strong">
          Loading
        </Typography>
      </LoadingOverlay>
    )
  }

  return <Outlet />
}

export default Dashboard
