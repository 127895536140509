import React, { useEffect } from "react"

import { Maintenance } from "@suraasa/error-pages"
import { CssBaseline, theme } from "@suraasa/placebo-ui"
import { checkStorageSupport, SessionStorageBanner } from "@suraasa/utils"
import { ThemeProvider } from "react-jss"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom"

import AppWrapper from "components/AppWrapper"
import GlobalState from "components/GlobalState"
import ErrorPage from "components/shared/ErrorPage"
import ExternalNavigate from "components/shared/ExternalNavigate"
import LazyLoadedRoute from "components/shared/LazyLoadedRoute"
import PrivateRoute from "components/shared/PrivateRoute"
import { getAuthInfo } from "utils/auth"
import { Platforms, Product } from "utils/constants"
import { GA } from "utils/googleAnalytics"
import { getPlatformURL } from "utils/helpers"
import { routes } from "utils/routes"
import ToastProvider from "utils/toast/ToastProvider"
import { useNetcore } from "utils/tracking/useNetcore"
// import AllNotifications from "views/AllNotifications"
// import CreateBetaPassword from "views/auth/CreateBetaPassword"
import ForgotPassword from "views/auth/ForgotPassword"
import HybridLogin from "views/auth/HybridLogin"
import IframeSSO from "views/auth/IframeSSO"
import Logout from "views/auth/Logout"
import OlympiadRegistrationFormSSO from "views/auth/OlympiadRegistrationFormSSO"
import PlatformLogout from "views/auth/PlatformLogout"
import PopupSSO from "views/auth/PopupSSO"
import ResetPasswordHandler from "views/auth/ResetPasswordHandler"
import SchoolForgotPassword from "views/auth/school/ForgotPassword"
import SchoolLogin from "views/auth/school/Login"
import SchoolSignUp from "views/auth/school/Signup"
import SignUp from "views/auth/SignUp"
import SVPLogin from "views/auth/SVPLogin"
import ZoomAuth from "views/auth/ZoomAuth"
import Dashboard from "views/Dashboard"
import Help from "views/help"
import VerificationProcess from "views/verification/VerificationProcess"
// import Profile from "views/profile"
// import EditProfile from "views/profile/Edit"

const SentryTest = React.lazy(() => import("views/SentryTest"))
const RyanTeacherSignUp = React.lazy(() => import("views/auth/ryan/Signup"))
// const PartnerLogin = React.lazy(() => import("views/auth/partner/Login"))

const SkillProfile = React.lazy(() => import("views/skillProfile"))
const ViewPublicSubmission = React.lazy(
  () => import("views/skillProfile/ViewPublicSubmission")
)

// import VerificationProcess from "views/verification/VerificationProcess"

function App() {
  const isStorageSupported = checkStorageSupport()

  useEffect(() => {
    const authInfo = getAuthInfo()
    GA.init()

    if (authInfo) GA.setUserId(authInfo.user.uuid)
  }, [])

  useNetcore()

  if (process.env.REACT_APP_MAINTENANCE === "true") {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Maintenance />
      </ThemeProvider>
    )
  }

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ToastProvider domRoot={document.body}>
          <CssBaseline />
          {!isStorageSupported && <SessionStorageBanner />}
          <Routes>
            <Route element={<AppWrapper />} path="/">
              <Route
                element={
                  <LazyLoadedRoute>
                    <SentryTest />
                  </LazyLoadedRoute>
                }
                path={routes.error}
              />
              <Route element={<PopupSSO />} path={routes.popupSSO} />

              <Route
                element={<OlympiadRegistrationFormSSO />}
                path={routes.olympiadRegistrationFormSSO}
              />

              <Route element={<IframeSSO />} path={routes.iframeSSO} />
              <Route element={<ZoomAuth />} path={routes.zoomAuth} />

              <Route
                element={<HybridLogin product={Product.learning} />}
                path={routes.login}
              />
              <Route
                element={<HybridLogin product={Product.internal} />}
                path={routes.internal.login}
              />
              <Route
                element={<HybridLogin product={Product.center} />}
                path={routes.centre.login}
              />
              <Route
                element={<HybridLogin product={Product.partner} />}
                path={routes.partner.login}
              />
              <Route element={<Logout />} path={routes.logout} />

              <Route
                element={
                  <LazyLoadedRoute>
                    <RyanTeacherSignUp />
                  </LazyLoadedRoute>
                }
                path={routes.ryanTeacherSignup}
              />
              <Route element={<SignUp />} path={routes.signUp} />
              <Route
                element={<ForgotPassword product={Product.learning} />}
                path={routes.forgotPassword}
              />
              <Route
                element={<ForgotPassword product={Product.internal} />}
                path={routes.internal.forgotPassword}
              />
              <Route
                element={<ForgotPassword product={Product.center} />}
                path={routes.centre.forgotPassword}
              />
              <Route
                element={<ForgotPassword product={Product.partner} />}
                path={routes.partner.forgotPassword}
              />
              <Route
                element={<ForgotPassword product={Product.school} />}
                path={routes.svp.forgotPassword}
              />
              <Route
                element={<ResetPasswordHandler />}
                path={routes.resetPassword}
              />
              <Route
                element={<ResetPasswordHandler createNew />}
                path={routes.createPassword}
              />

              {/* <Route
                element={<CreateBetaPassword />}
                path={routes.createBetaPassword}
              /> */}

              {/* This is a fail-safe just in case user lands on /reset-password. They need to be redirected */}
              <Route
                element={<Navigate to={routes.forgotPassword} />}
                path="/reset-password"
              />

              <Route element={<SVPLogin />} path={routes.svp.login} />

              {/* Authentication routes for School platform */}
              <Route element={<SchoolLogin />} path={routes.school.login} />

              <Route
                element={<PlatformLogout platform={Platforms.school} />}
                path={routes.school.logout}
              />
              <Route element={<SchoolSignUp />} path={routes.school.signUp} />

              <Route
                element={<SchoolForgotPassword />}
                path={routes.school.forgotPassword}
              />

              {/* Authentication routes for Partner platform */}
              {/* <Route
                element={
                  <LazyLoadedRoute>
                    <PartnerLogin />
                  </LazyLoadedRoute>
                }
                path={routes.partner.login}
              /> */}
              <Route
                element={<ForgotPassword product={Product.partner} />}
                path={routes.partner.forgotPassword}
              />

              <Route
                element={
                  <PlatformLogout platform={Platforms.olympiadPartner} />
                }
                path={routes.partner.logout}
              />

              {/*
               * These routes are wrapped in GlobalState because apart from PrivateRoute
               * all other routes are public but even a logged in user can open them,
               * if a logged user opens these routes, user photo, name has to be visible in
               * Navbar the data for which is in global state.
               */}
              <Route element={<GlobalState />} path="/">
                <Route
                  element={
                    <LazyLoadedRoute>
                      <Help />
                    </LazyLoadedRoute>
                  }
                  path={routes.help}
                />

                <Route
                  element={<VerificationProcess />}
                  path={routes.verificationProcess}
                />

                {/* Profile is now on LMS itself */}
                <Route
                  element={
                    <ExternalNavigate
                      params={["username"]}
                      to={getPlatformURL("learn", "/profile/:username")}
                    />
                  }
                  path={routes.publicProfile}
                />
                <Route
                  element={
                    <LazyLoadedRoute>
                      <SkillProfile />
                    </LazyLoadedRoute>
                  }
                  path={routes.publicSkillProfile}
                />
                <Route
                  element={
                    <LazyLoadedRoute>
                      <ViewPublicSubmission />
                    </LazyLoadedRoute>
                  }
                  path={routes.skillEvidence}
                />

                <Route element={<PrivateRoute />} path="/">
                  <Route element={<Dashboard />} path={routes.dashboard}>
                    {/* Profile is now on LMS itself */}
                    <Route
                      element={
                        <ExternalNavigate
                          params={["username"]}
                          to={getPlatformURL("learn", "/profile")}
                        />
                      }
                      path={routes.profile}
                    />
                    <Route
                      element={
                        <LazyLoadedRoute>
                          <SkillProfile />
                        </LazyLoadedRoute>
                      }
                      path={routes.skillProfile}
                    />
                  </Route>
                </Route>
                <Route element={<ErrorPage errorCode={404} />} path="*" />
              </Route>

              <Route element={<ErrorPage errorCode={404} />} path="*" />
            </Route>
          </Routes>
        </ToastProvider>
      </ThemeProvider>
    </Router>
  )
}

export default App
