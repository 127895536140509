import React from "react"

import clsx from "clsx"
import { createUseStyles } from "react-jss"

import { PARTNER_PLATFORM_URL } from "utils/constants"

const useStyles = createUseStyles({
  logo: {
    textDecorationLine: "none",
  },
})

const PartnerNavLogo = () => {
  const classes = useStyles()

  return (
    <a
      className={clsx("flex gap-2 items-center", classes.logo)}
      href={PARTNER_PLATFORM_URL}
    >
      <img
        alt="ito-partner"
        className="hidden md:block"
        height="28"
        src="https://assets.suraasa.com/ito/logos/ito-partner-one-line.svg"
        width="400"
      />
      <img
        alt="ito-partner"
        className="hidden sm:block md:hidden"
        src="https://assets.suraasa.com/ito/logos/ito-partner-two-line.svg"
        width="225"
      />
      <img
        alt="ito-partner"
        className="sm:hidden"
        height="40"
        src="https://assets.suraasa.com/ito/logos/ito-original-mini.svg"
        width="40"
      />
    </a>
  )
}

export default PartnerNavLogo
