import React, { useEffect } from "react"

import { Button, Divider, Typography } from "@suraasa/placebo-ui"
import { createUseStyles } from "react-jss"
import { NavLink } from "react-router-dom"
import { CSSTransition } from "react-transition-group"

import { zIndex } from "utils/config"
import { getPlatformURL } from "utils/helpers"
import { ToggleValue } from "utils/hooks/useToggle"

const duration = 225
const sideBarWidth = 300
const cubicBezier = "cubic-bezier(0, 0, 0.2, 1) 0ms"
const useStyles = createUseStyles(theme => ({
  root: {
    background: "white",
    position: "absolute",
    left: -sideBarWidth,
    top: "62px",
    height: "calc(100vh - 62px)",
    width: sideBarWidth,
    zIndex: zIndex.sidebar,
    transition: `left ${duration}ms ${cubicBezier}`,
    willChange: "left",
    overflowY: "auto",
    "&.enter-active": { left: 0 },
    "&.enter-done": { left: 0 },
    "&.exit-active": { left: -sideBarWidth },
    "&.exit-done": { left: -sideBarWidth },
  },

  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  backdrop: {
    width: "100%",
    height: "100%",
    position: "fixed",
    left: 0,
    zIndex: zIndex.sidebar - 1,
    top: 0,
    backgroundColor: "transparent",
    backdropFilter: "blur(0px)",
    transition: `background-color backdrop-filter ${duration}ms ${cubicBezier}`,
    willChange: "background-color backdrop-filter",

    "&.enter-active": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.enter-done": {
      backgroundColor: "rgba(0,0,0,0.25)",
      backdropFilter: "blur(2px)",
    },
    "&.exit-active": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
    "&.exit-done": {
      backgroundColor: "transparent",
      backdropFilter: "blur(0px)",
    },
  },

  title: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    textDecoration: "none",
    margin: theme.spacing(0, 1.5),
  },

  sideBarElement: {
    borderRadius: theme.spacing(1 / 2),
    color: theme.colors.onSurface[800],
    margin: theme.spacing(0.1, 1.5),
    padding: theme.spacing(10 / 8, 2),
    textDecoration: "none",
    background: "white",
    fontWeight: 600,
    transition: "all 0.2s",
    "&:hover": {
      cursor: "pointer",
      background: theme.colors.onSurface[50],
    },
    "&:active": {
      boxShadow: "inset 0px -1px 4px rgba(0, 0, 0, 0.07)",
    },
    // react-router puts .active class to active NavLink
    "&.active": {
      color: theme.colors.onSurface[800],
      padding: theme.spacing(10 / 8, 2),
      background: theme.colors.interactive[50],

      "&:hover": {
        cursor: "pointer",
        background: theme.colors.interactive[100],
      },
      "&:active": {
        boxShadow: "inset 0px -1px 4px rgba(0, 0, 0, 0.07)",
      },
    },
  },

  divider: {
    marginTop: theme.spacing(1),
  },

  endElement: {
    color: theme.colors.onSurface[800],
    padding: theme.spacing(10 / 8, 2),
    background: "white",
    fontWeight: 500,
  },

  gutterBottom: {
    padding: theme.spacing(1 / 2, 0),
    width: "100%",
  },
}))

const jobsUrl = process.env.REACT_APP_JOBS_PLATFORM_URL

const sideBarItems = [
  {
    name: "Learn",
    isHidden: false,
    children: [
      {
        name: "Assessments",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/assessments"),
      },
      {
        name: "Assignments",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/assignments"),
      },
      {
        name: "Discussions",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/discussions"),
      },
      {
        name: "Classrooms",
        isExternal: true,
        link: getPlatformURL("learn", "/dashboard/attendance"),
      },
    ],
  },
  {
    name: "Jobs",
    isHidden: Boolean(!jobsUrl),
    children: [
      { name: "Dashboard", isExternal: true, link: `${jobsUrl}/home` },
    ],
  },
  {
    name: "Store",
    isHidden: false,
    children: [
      {
        name: "Browse",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store"),
      },
      {
        name: "Courses",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store/courses"),
      },
      {
        name: "Qualifications",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store/qualifications"),
      },
      {
        name: "Certifications",
        isExternal: true,
        link: getPlatformURL("suraasa", "/store/certifications"),
      },
    ],
  },
  {
    name: "Help",
    isHidden: false,
    children: [
      {
        name: "Reach us for help",
        isExternal: false,
        link: "/help",
      },
      // { name: "Change Language", isExternal: true, link: "/change language" },
    ],
  },
]

type Props = {
  open: boolean
  toggle: ToggleValue
}

const Sidebar = ({ open, toggle }: Props) => {
  const classes = useStyles()

  /**
   * Disable scrollbar, prevent content shift when sidebar opens
   */
  useEffect(() => {
    const { body } = document
    const nav = document.querySelector("nav")
    const scrollBarWidth = window.innerWidth - body.clientWidth

    if (open) {
      body.style.paddingRight = `${scrollBarWidth}px`
      if (nav) {
        nav.style.marginRight = `-${scrollBarWidth}px`
      }
      body.style.overflow = "hidden"
    } else {
      if (nav) {
        nav.style.marginRight = `0px`
      }
      body.style.paddingRight = `0px`
      body.style.overflow = "auto"
    }
  }, [open])

  useEffect(() => {
    function handleEsc(e: KeyboardEvent) {
      if (e.key === "Escape") {
        toggle(false)
      }
    }
    document.addEventListener("keydown", handleEsc)
    return () => {
      document.removeEventListener("keydown", handleEsc)
    }
  }, [toggle])

  return (
    <>
      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div className={classes.root}>
          <div className={classes.content}>
            {sideBarItems
              .filter(({ isHidden }) => !isHidden)
              .map(({ name, children }) => (
                <React.Fragment key={name}>
                  <Typography
                    className={classes.title}
                    color="onSurface.400"
                    key={name}
                    variant="preTitle"
                  >
                    {name}
                  </Typography>
                  {children.map(item => {
                    if (item.link) {
                      if (item.isExternal) {
                        return (
                          <a
                            className={classes.sideBarElement}
                            href={item.link}
                            key={item.name}
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                        )
                      }
                      return (
                        <NavLink
                          className={classes.sideBarElement}
                          key={item.name}
                          to={item.link}
                        >
                          {item.name}
                        </NavLink>
                      )
                    }
                    return null
                  })}
                </React.Fragment>
              ))}
            <div className="mt-auto">
              <Divider className={classes.divider} weight="light" />
            </div>
            <Button
              className="mt-1 mx-2.5"
              color="black"
              component="a"
              href="https://www.suraasa.com/privacy-policy"
              variant="text"
            >
              Privacy Policy
            </Button>
            <div className={classes.gutterBottom} />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        in={open}
        timeout={duration}
        appear
        mountOnEnter
        unmountOnExit
      >
        <div
          className={classes.backdrop}
          role="presentation"
          onClick={() => toggle(false)}
        />
      </CSSTransition>
    </>
  )
}

export default Sidebar
