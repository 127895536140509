import {
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Theme,
  Typography,
} from "@suraasa/placebo-ui"
import { ReactComponent as Logo } from "assets/logos/suraasa-logo-new.svg"
import Banner from "assets/verificationProcess/banner.png"
import clsx from "clsx"
import { createUseStyles, useTheme } from "react-jss"
import { useNavigate } from "react-router-dom"

import { Cancel } from "iconoir-react"

const useStyles = createUseStyles(theme => ({
  dialog: {
    width: "100%",

    "& .DialogTitle-container": {
      padding: 0,
    },

    // TODO: placebo-issue add slot for dialogTitle
    "& .DialogTitle-container > div": {
      width: "100%",

      "& > p": {
        width: "100%",
      },
    },
  },
  logo: {
    width: "max-content",
    height: "20px",
  },

  bannerContainer: {
    width: "800px",
    maxWidth: "100%",
    margin: "auto",
    "& img": {
      width: "100%",
    },
  },
  cancelButton: {
    borderRadius: "100%",
    width: "27px",
    height: "27px",
    border: `2px solid ${theme.colors.onSurface[500]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    maxWidth: "580px",
    margin: `auto`,
  },

  stepperRoot: {
    display: "flex",
  },
  stepperLine: {
    width: "1px",
    background: theme.colors.onSurface[300],
    transform: "translateY(18px)",
    height: "100%",
  },
  hidden: {
    opacity: "0",
  },
  stepperIcon: {
    width: "10px",
    height: "10px",
    background: theme.colors.onSurface[300],
    borderRadius: "50%",
    transform: "translateX(-55%) translateY(18px)",
  },
  stepperContent: {
    marginLeft: theme.spacing(2.5),
    marginBottom: theme.spacing(3),
  },
}))

const steps = [
  {
    title: "Learner Submits Assignment",
    stepColor: "#4B83EE",
    titleColor: "#1148B3",
    content:
      "After the completion of a course / milestone, learners are allotted assignments with defined rubrics. The learners write them digitally and submit on Suraasa.",
  },
  {
    title: "Plagiarism Check",
    stepColor: "#4785D0",
    titleColor: "#23528B",
    content:
      "After the learner submits the assignment, our advances plagiarism detection systems check the assignments for plagiarism against other assignments as well as related articles on the internet. If any assignment fails this check, learners are required to redo the assignment.",
  },
  {
    title: "Marked by Professor",
    stepColor: "#4487B2",
    titleColor: "#2A546F",
    content:
      "Once plagiarism check is cleared, our experienced international professors evaluate the assignment based on carefully defined rubrics. If a learner's assignment isn't upto the required standards, they have to redo the assignment.",
  },
  {
    title: "Quality Assured by Us",
    stepColor: "#428893",
    titleColor: "#29555C",
    content:
      "After evaluation by the professors, we run the assignment through a quality assurance process to double-check its authenticity and validity. This helps us to also ensure fairness in evaluation.",
  },
  {
    title: "Assignment Checked Successfully",
    stepColor: "#408B76",
    titleColor: "#28574A",
    content:
      "Only after it clears the above 4 steps, the assignment is successfully verified and added to the learner's skill portfolio.",
  },
]

const Stepper = () => {
  const classes = useStyles()

  return (
    <div>
      {steps.map((step, index) => (
        <div className={classes.stepperRoot} key={index}>
          <div>
            <div
              className={clsx(classes.stepperLine, {
                [classes.hidden]: index === steps.length - 1,
              })}
            />
          </div>

          <div>
            <div className={classes.stepperIcon} />
            <div className={classes.stepperContent}>
              <Typography
                className="mb-0.25"
                style={{ color: step.stepColor }}
                variant="title2"
              >
                {(index + 1).toString().padStart(2, "0")}
              </Typography>
              <Typography
                className="mb-0.25"
                style={{ color: step.titleColor }}
                variant="strong"
              >
                {step.title}
              </Typography>
              <Typography variant="smallBody">{step.content}</Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const VerificationProcess = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const theme = useTheme<Theme>()

  return (
    <Dialog animation="slide" className={classes.dialog} fullScreen open>
      <DialogTitle>
        <Container>
          <span className="flex items-center justify-between py-1.5">
            <a href={process.env.REACT_APP_LEARN_PLATFORM_URL}>
              <Logo className={classes.logo} />
            </a>
            <IconButton
              className={classes.cancelButton}
              onClick={() => {
                navigate(-1)
              }}
            >
              <Cancel color={theme.colors.onSurface[500]} />
            </IconButton>
          </span>
        </Container>
      </DialogTitle>
      <DialogContent>
        <div>
          <div className={classes.bannerContainer}>
            <img alt="banner" className="my-4" src={Banner} />
            <Divider weight="light" />
          </div>
          <div className={clsx(classes.content, "p-3")}>
            <Typography
              className="mb-3 mt-3"
              color="primary.800"
              variant="title1"
            >
              How Suraasa verifies every single assignment
            </Typography>
            <Stepper />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default VerificationProcess
