import React from "react"

import * as Sentry from "@sentry/react"
import ReactDOM from "react-dom"

import ErrorScreen from "components/ErrorScreen"
import { getAuthInfo } from "utils/auth"
import { checkEnvVars } from "utils/helpers"

import { version } from "../package.json"

import App from "./App"

import "./styles/index.css"
import "./styles/tailwind.generated.css"

checkEnvVars([
  "REACT_APP_API_ENDPOINT",
  "REACT_APP_SCHOOL_PLATFORM_URL",
  "REACT_APP_PARTNER_PLATFORM_URL",
  "REACT_APP_SVP_PLATFORM_URL",
  "REACT_APP_LEARN_PLATFORM_URL",
  "REACT_APP_SURAASA_PLATFORM_URL",
  "REACT_APP_ADMISSIONS_ADMIN_PLATFORM_URL",
])

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: version,
  })
  const authInfo = getAuthInfo()
  if (authInfo)
    Sentry.setUser({ id: authInfo.user.uuid, email: authInfo.user.email })
}

/**
 * react-jss does has issues with
 * functional styles that use media queries.
 * https://github.com/cssinjs/jss/issues/1320
 *
 * * Disabling strict mode fixes this issue
 */

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={
      <ErrorScreen error="An unexpected error occurred. Please try reloading the page." />
    }
  >
    <App />
  </Sentry.ErrorBoundary>,
  document.getElementById("root") || document.createElement("div")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)
