import { Notification } from "@suraasa/notifications"

type BaseNotificationAction =
  | {
      name: "JOB_OFFER_RECEIVED"
      data: {
        jobOfferId: number
      }
    }
  | {
      name:
        | "INTERVIEW_SCHEDULED"
        | "LIVE_DEMO_SCHEDULED"
        | "SCHOOL_ASSESSMENT_SCHEDULED"
      data: {
        jobId: number
        jobApplicant: number
        jobApplicantStepId: number
      }
    }
  | {
      name: "INVITE_RECEIVED"
      data: { jobApplicantId: number }
    }
  | {
      name: "JOB_PUBLISHED"
      data: {
        jobSlug: string
        schoolSlug: string
      }
    }

type FinancialNotificationAction =
  | {
      name: "ITEMS_ALLOCATED"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "DUE_DATE_APPROACHING"
      data: {
        orderId: string
      }
    }
  | {
      name: "TRANSACTION_SUCCESSFUL"
      data: {
        orderId: string
      }
    }
  | {
      name: "TRANSACTION_FAILED"
      data: {
        orderId: string
      }
    }

export type NotificationAction =
  | BaseNotificationAction
  | LMSNotificationAction
  | FinancialNotificationAction

/**
 * This is the format in which backend sends us data.
 * We then convert this data into `LMSNotificationAction` using the formatter below
 */
type FetchedLMSNotificationAction =
  | {
      name: "REDIRECT_TO_DISCUSSION_DETAIL" | "REDIRECT_TO_DISCUSSIONS_DETAIL"
      data: { userDiscussionId: string; commentId?: string; replyId?: string }
    }
  | {
      name: "REDIRECT_TO_TRENDING_DISCUSSIONS"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "REDIRECT_TO_ASSESSMENT_DETAIL"
      data: { assessmentUuid?: string }
    }
  | {
      name: "REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL"
      data: { assignmentUuid?: string; submissionUuid?: string }
    }
  | {
      name: "REDIRECT_TO_CLASS_DETAIL"
      data: { classUuid: string }
    }
  | {
      name: "REDIRECT_TO_CENTRE_SCHEDULE_DETAIL"
      data: { centerUuid: string }
    }
  | {
      name: "REDIRECT_TO_MY_LIBRARY"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "REDIRECT_TO_COURSE_CERTIFICATE_DETAIL"
      data: {
        courseId: string
        courseName?: string
        certificateNumber?: number
      }
    }
  | {
      name: "REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL"
      data: {
        certificationId: string
        certificationName: string
        certificateNumber: string
      }
    }
  | {
      name: "REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL"
      data: {
        qualificationId: string
        qualificationName: string
        certificateNumber: string
      }
    }
  | {
      name: "REDIRECT_TO_HOMEPAGE"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "REDIRECT_TO_ORDER_DETAIL"
      data: { orderId: string }
    }

/**
 * Formatted LMS Notifications
 */
export type LMSNotificationAction =
  | {
      name: "REDIRECT_TO_DISCUSSION_DETAIL" | "REDIRECT_TO_DISCUSSIONS_DETAIL"
      data: {
        userDiscussionId: string
        commentId?: string
        replyId?: string
      }
    }
  | {
      name: "REDIRECT_TO_TRENDING_DISCUSSIONS"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "REDIRECT_TO_ASSESSMENT_DETAIL"
      data: {
        assessmentUuid?: string
      }
    }
  | {
      name: "REDIRECT_TO_ASSIGNMENT_PAGE_DETAIL"
      data: {
        assignmentUuid?: string
        submissionUuid?: string
      }
    }
  | {
      name: "REDIRECT_TO_CLASS_DETAIL"
      data: {
        classUuid: string
      }
    }
  | {
      name: "REDIRECT_TO_CENTRE_SCHEDULE_DETAIL"
      data: {
        centerUuid: string
      }
    }
  | {
      name: "REDIRECT_TO_MY_LIBRARY"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "REDIRECT_TO_COURSE_CERTIFICATE_DETAIL"
      data: {
        courseId: string
        courseName?: string
        certificateNumber?: number
      }
    }
  | {
      name: "REDIRECT_TO_CERTIFICATION_CERTIFICATE_DETAIL"
      data: {
        certificationId: string
        certificationName: string
        certificateNumber: string
      }
    }
  | {
      name: "REDIRECT_TO_QUALIFICATION_CERTIFICATE_DETAIL"
      data: {
        qualificationId: string
        qualificationName: string
        certificateNumber: string
      }
    }
  | {
      name: "REDIRECT_TO_HOMEPAGE"
      // eslint-disable-next-line @typescript-eslint/ban-types
      data: {}
    }
  | {
      name: "REDIRECT_TO_ORDER_DETAIL"
      data: { orderId: string }
    }

/**
 * We convert the original data so it matches the new notification structure
 * This will be removed when backend refactors LMS notifications.
 */
export const formatNotifications = (
  notifications: Notification<
    BaseNotificationAction | FetchedLMSNotificationAction
  >[]
) => {
  if (!notifications) return null
  return notifications.map(notification => {
    if (notification.action && "name" in notification.action) {
      return {
        ...notification,
        data: {
          action: {
            type: notification.action.name,
            data: {
              ...notification.action,
            },
          },
        },
      }
    }
    return notification
  }) as Notification<NotificationAction>[]
}
